<template>
  <div class="college-content">
    <img
      class="upload"
      src="../../assets/images/College/upload.png"
      alt=""
      @click="uploadModalShow = true"
    />
    <img class="header" src="../../assets/images/College/header.png" alt="" />
    <img class="bg" src="../../assets/images/College/bg.png" alt="" />
    <div class="items" v-show="!detailShow">
      <div
        class="item"
        v-for="(item, index) in videoList"
        :key="index"
        @click="
          video = item;
          detailShow = true;
        "
      >
        <div class="img">
          <img class="item-img" :src="item.image" alt="" />
          <img
            class="play"
            src="../../assets/images/College/play.png"
            alt=""
            v-if="item.file"
          />
        </div>
        <div class="title">{{ item.name }}</div>
      </div>
      <!-- 占位 -->
      <div class="item"></div>
      <div class="item"></div>
      <div class="item"></div>
      <div class="item"></div>
    </div>
    <div class="items" v-show="detailShow">
      <div class="back" @click="detailShow = false">返回学院列表</div>
      <div class="detail" v-loading="loading">
        <div class="top">
          <div class="img" @click="showVideo(video)">
            <img class="item-img" :src="video.image" alt="" />
            <img class="play" src="../../assets/images/College/play.png" alt="" />
          </div>
          <div class="right">
            <div class="title">{{ video.name }}</div>
            <div class="price">￥{{ video.price }}</div>
            <div class="img" v-if="video.file">已购买</div>
            <div class="cloud" v-if="video.cloud_storage">
              <span @click="open(video.cloud_storage)">去云盘查看</span>
              <span
                v-clipboard:copy="video.share_code"
                v-clipboard:success="onCopy"
                v-if="video.cloud_storage"
                style="color: #fcf499; margin-left: 10px"
              >
                复制分享码
              </span>
            </div>
            <div class="img" @click="payment" v-if="!video.file">
              <img src="../../assets/images/College/buy.png" alt="" />
            </div>
          </div>
        </div>
        <div class="text">
          {{ video.intro }}
        </div>
      </div>
    </div>
    <div class="uploadModal" v-show="uploadModalShow">
      <el-form
        ref="ruleForm"
        class="form"
        label-position="top"
        :model="formLabelAlign"
        :rules="rules"
      >
        <el-form-item label="1.文件名" prop="name">
          <el-input v-model="formLabelAlign.name"></el-input>
        </el-form-item>
        <el-form-item label="2.价格（元）" style="width: 20%" prop="price">
          <el-input v-model="formLabelAlign.price"></el-input>
        </el-form-item>
        <el-form-item label="3.内容简介" prop="intro">
          <el-input
            type="textarea"
            :autosize="{ minRows: 3 }"
            v-model="formLabelAlign.intro"
          ></el-input>
        </el-form-item>
        <el-form-item label="4.视频封面" prop="image">
          <el-upload
            :class="{ hide: hideUploadCoverEdit }"
            :action="uploadAction"
            :on-success="handleUploadCoverSuccess"
            :on-remove="handleUploadCoverRemove"
            list-type="picture-card"
            ref="uploadCover"
          >
            <img
              class="upload-img"
              src="../../assets/images/College/upload-button-bg.png"
              alt=""
            />
            <div class="upload-text-cover">点击上传</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="5.视频上传" prop="file">
          <el-upload
            :class="{ hide: hideUploadEdit }"
            :action="uploadAction"
            :on-success="handleUploadSuccess"
            :on-remove="handleUploadRemove"
            accept="video/mp4"
            ref="upload"
          >
            <div class="uploader">
              <img
                class="upload-img"
                src="../../assets/images/College/upload-button-bg.png"
                alt=""
              />
              <div class="upload-text">点击上传</div>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="6.云盘地址" prop="cloud_storage">
          <el-input v-model="formLabelAlign.cloud_storage"></el-input>
        </el-form-item>
        <el-form-item label="7.云盘分享码">
          <el-input v-model="formLabelAlign.share_code"></el-input>
        </el-form-item>
      </el-form>
      <div class="buttons">
        <img
          src="../../assets/images/College/cancel.png"
          alt="取消"
          @click="uploadModalShow = false"
        />
        <img src="../../assets/images/College/affirm.png" alt="上传" @click="submit" />
      </div>
    </div>
    <div class="paymentImg" v-if="paymentImg">
      <i class="el-icon-circle-close" @click="close"></i>
      <img :src="paymentImg" alt="" />
      微信扫码支付
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
      videoList: [],
      video: {},
      detailShow: false,
      uploadModalShow: false,
      formLabelAlign: {
        name: "",
        price: "",
        intro: "",
        image: "",
        file: "",
        cloud_storage: "",
        share_code: "",
      },
      uploadAction: "",
      hideUploadEdit: false, // 是否隐藏上传按钮
      hideUploadCoverEdit: false,
      rules: {
        name: [{ required: true, message: "请输入文件名", trigger: "change" }],
        price: [{ required: true, message: "请输入价格", trigger: "change" }],
        intro: [
          { required: true, message: "请输入内容简介", trigger: "change" },
        ],
        image: [
          { required: true, message: "请上传视频封面", trigger: "change" },
        ],
        file: [{ required: true, message: "请上传视频", trigger: "change" }],
        cloud_storage: [
          { required: true, message: "请输入云盘地址", trigger: "change" },
        ],
      },
      paymentImg: "",
      timer: false,
    };
  },
  mounted() {
    this.uploadAction = axios.defaults.baseURL + "api/common/upload";
    this.getVideoList();
  },
  methods: {
    // 获取列表
    getVideoList() {
      this.$api.video.list().then((res) => {
        this.videoList = res.data;
      });
    },
    // 上传
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$api.video.add(this.formLabelAlign).then(() => {
            this.getVideoList();
            this.uploadModalShow = false;
          });
        }
      });
    },
    // 封面上传成功
    handleUploadCoverSuccess(res) {
      if (res.code == 0) {
        this.$message.error(res.msg);
        this.$refs.uploadCover.clearFiles();
        return;
      }
      this.formLabelAlign.image = res.data.url;
      this.hideUploadCoverEdit = true;
    },
    // 删除封面
    handleUploadCoverRemove() {
      this.formLabelAlign.image = "";
      setTimeout(() => {
        this.hideUploadCoverEdit = false;
      }, 500);
    },
    // 视频上传成功
    handleUploadSuccess(res) {
      if (res.code == 0) {
        this.$message.error(res.msg);
        this.$refs.upload.clearFiles();
        return;
      }
      this.formLabelAlign.file = res.data.url;
      this.hideUploadEdit = true;
    },
    // 删除视频
    handleUploadRemove() {
      this.formLabelAlign.file = "";
      setTimeout(() => {
        this.hideUploadEdit = false;
      }, 500);
    },
    // 购买
    payment() {
      this.loading = true;
      this.$api.video.payment({ id: this.video.id }).then((res) => {
        this.paymentImg = res.data.paymentImg;
        this.checkOrder(res.data.orderid);
        this.loading = false;
      });
    },
    // 检查订单
    checkOrder(orderid) {
      this.timer = setInterval(() => {
        this.$api.video.check_order({ orderid }).then((res) => {
          if (res.data == 1) {
            clearInterval(this.timer);
            this.paymentImg = false;
            this.detailShow = false;
            this.getVideoList();
          }
        });
      }, 1000);
    },
    // 播放视频
    showVideo(item) {
      if (item.file) {
        this.open(item.file);
      } else {
        this.$message("未购买当前视频");
      }
    },
    onCopy() {
      this.$message("复制成功");
    },
    open(url) {
      window.open(url);
    },
    close() {
      clearInterval(this.timer);
      this.paymentImg = false;
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style>
.el-form-item {
  margin-bottom: 1vw;
}
.hide .el-upload--text,
.hide .el-upload--picture-card {
  display: none;
}
</style>
<style lang="less">
.college-content {
  position: relative;
  top: -1vw;
  .upload {
    position: absolute;
    right: 0;
    top: -2%;
    cursor: pointer;
  }
  .bg {
    position: absolute;
    top: 8%;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 35vw;
  }
  .items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 5% 5% 0 5%;

    height: 25vw;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .item {
      width: 24%;
      margin-left: 1%;
      margin-bottom: 2%;
      cursor: pointer;
      .img {
        position: relative;
        img {
          width: 100%;
          max-height: 12vw;
          object-fit: cover;
        }
        .play {
          position: absolute;
          width: 10%;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .title {
        font-size: 11px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #ffffff;
        line-height: 32px;
        margin-top: 1%;
      }
    }

    .back {
      font-size: 1.1vw;
      color: #ffffff;
      position: absolute;
      top: 0;
      cursor: pointer;
    }
    .detail {
      .top {
        display: flex;
        .img {
          width: 30%;
          position: relative;
          .play {
            position: absolute;
            width: 10%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          img {
            width: 100%;
            max-height: 12vw;
          }
        }
        .right {
          margin-left: 2%;
          text-align: left;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title {
            font-size: 1.3vw;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #ffffff;
          }
          .price {
            font-size: 1vw;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #f90c0c;
          }
          .img {
            width: 10vw;
            img {
              width: 100%;
            }
          }
        }
        .cloud {
          color: #fff;
          cursor: pointer;
        }
      }
      .text {
        font-size: 1.1vw;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #ffffff;
        line-height: 37px;
        margin-top: 5%;
        text-align: left;
      }
    }
  }

  .uploadModal {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
    margin: 0 auto;
    background: url("../../assets/images/College/upload-bg.png") no-repeat;
    background-size: 100%;
    padding: 6% 3% 3% 3%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .uploader {
      padding: 1vw 0;
      border-radius: 4px;
      border: 1px solid #ffffff;
      width: 10vw;
    }
    .upload-text {
      font-size: 10px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
    }

    .upload-text-cover {
      font-size: 10px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      position: absolute;
      top: 2vw;
      left: 0;
      width: 100%;
    }
    .form {
      height: 28vw;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .avatar-uploader .el-upload {
      border: 1px solid #d9d9d9;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      padding: 3% 5% 1% 5%;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }
    .buttons {
      width: 100%;
      float: right;
      display: flex;
      justify-content: flex-end;
      img {
        width: 10%;
        margin-left: 2%;
        cursor: pointer;
      }
    }
  }
}
.paymentImg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
  .el-icon-circle-close {
    font-size: 20px;
    cursor: pointer;
  }
}
</style>
